import React from 'react';
import styled from 'styled-components';

import Layout from 'components/Order/Layout';
import TitleName from 'components/UI/Header/TitleName';

import { md } from 'utils/breakpoints';

const Container = styled.div``;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #000000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const UlBlock = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;

  > div:nth-child(2) {
    padding-left: 15px;
  }
`;

const BlockTitle = styled.div`
  font-weight: 500;
`;

const ShoppingInformation = (props) => {
  const pageTitle = '購物須知';

  return (
    <Layout {...props} pageTitle={pageTitle}>
      <TitleName />
      <Container>
        <TitleBar>{pageTitle}</TitleBar>
        <Card>
          <div>
            所有訂單狀態,只要您是家樂福線上購物網會員,您皆可以至「我的家樂福-我的帳戶」(點選後直接到會員中心的訂單查詢頁),查詢訂單目前狀態。若您未加入家樂福購物網會員,因為系統中無您的會員資料,故無法提供訂單狀態線上查詢。
          </div>
          <BlockTitle>《購物流程》</BlockTitle>
          <div>
            所有訂單狀態,只要您是家樂福線上購物網會員,您皆可以至「我的家樂福-我的帳戶」(點選後直接到會員中心的訂單查詢頁),查詢訂單目前狀態。若您未加入家樂福購物網會員,因為系統中無您的會員資料,故無法提供訂單狀態線上查詢。
          </div>
          <UlBlock>
            <BlockTitle>．選擇購買商品</BlockTitle>
            <div>
              您可以透過商品分類、館別、各類推薦等方式來挑選商品,也可以利用搜尋的方式,快速尋找您所需要的商品。
            </div>
          </UlBlock>
          <UlBlock>
            <BlockTitle>．加入購物車</BlockTitle>
            <div>
              選擇完商品後,您可以按下「加入購物車」按鈕,將商品放入購物車內,接著點選右側購物車圖標,進入購物車頁面。
            </div>
          </UlBlock>
          <UlBlock>
            <BlockTitle>．購物車商品確認及結算</BlockTitle>
            <div>
              確認購物車的商品及金額,選擇的物流方式,點擊付款方式按鈕:
              <br />
              如果您是已登錄的狀態,則會直接進入結算頁面;果您還未登錄,您可以直接登錄後進入結算頁面;
            </div>
          </UlBlock>
          <UlBlock>
            <BlockTitle>．填寫配送資料</BlockTitle>
            <div>
              如果您是登錄的狀態,需要填寫收件人或者取貨人的訊息,發票抬頭訊息及備註。
              <br />
              如果您是非會員購物,除了填寫收件人或者取貨人的訊息,發票抬頭訊息及備註之外,還需要填寫購買人的訊息,家樂福線上購物網會自動幫您註冊成為會員。
            </div>
          </UlBlock>
          <UlBlock>
            <BlockTitle>．確定送出-送出訂購需求</BlockTitle>
            <div>
              填寫完相關資訊後,點擊確定送出按鈕,則會送出您的訂購需求,如果付款方式選擇的信用卡一次付清或者WEB
              ATM,則同時會跳轉到玉山銀行的結帳頁面,顧客支付完畢後,則會進行後續備貨處理。如果付款方式選擇的實體ATM,請在付款繳費期限內將訂單貨款匯入指定的銀行帳號內,否則,逾期後訂單自動取消。
            </div>
          </UlBlock>
          <UlBlock>
            <BlockTitle>．前往指定店別取貨</BlockTitle>
            <div>
              如果物流方式選擇的是店取,訂單生成後,顧客可以根據選擇的取貨日期和取貨時段去取貨門店取貨,建議在取貨前先與門店電話預約取貨時段,減少等待時間。
            </div>
          </UlBlock>
          <UlBlock>
            <BlockTitle>．宅配到府</BlockTitle>
            <div>
              如果物流方式選擇的是宅配,送出訂單需求並經確認後,家樂福線上購物網會在您選擇的配送日期和配送時段將貨物送到您府中。本公司保留接受訂單成立與否之權利,若因交易條件有誤、商品無庫存,或有其他本公司無法接受訂單之情形,本公司將以
              電話/ EMAIL通知您訂單不成立/取消。
            </div>
          </UlBlock>
          <BlockTitle>《付款及取貨注意事項》</BlockTitle>
          <div>
            您可以選擇信用卡付款、電子支付。
            <br />
            目前僅接受一般本國銀行或外國銀行於本國設立分行發行之VISA、Master、JCB等三家
            <br />
            國際發卡組織之信用卡,其餘外國銀行發行之信用卡恕不適用信用卡付款,建議改用電子支付。
            <br />
            若您選擇「信用卡」為付款方式,需請您依訂單金額先繳款,在帳款入帳後,出貨分店
            才會開始為您備貨及安排配送。若完成付款時間超過訂購日當日16:00,則配送日
            會依據您匯款完成日期再展延一日。
            <br />
            若您選擇「電子支付」為付款方式,依不同手機系統,有Apple Pay、SamSung
            Pay Google Pay、LINE Pay等選擇。
            一般訂單之購物車提供所有支付方式;其他購物車如預購購物車、廠商直送購物車等,
            僅提供部分支付方式。
            <br />
            若訂單於期限內未完成付款(信用卡72小時未付款,電子支付逾2小時未付款),家樂福線上購物網將會自動取消該筆訂單,敬請見諒!
            <br />
            ※請注意,若您使用「家樂福玉山聯名卡」並選擇「電子支付」為付款方式,則不適用2
            倍紅利回饋。(電子支付包含Apple Pay、SamSung Pay、Google Pay、LINE
            Pay等) Apple Pay適用裝置:使用iOS
            手機開家樂福APP、使用safari瀏覽器(只支援apple瀏覽器)。
            <br />
            到店取貨:
            <br />
            若您選擇到店取貨,線上完成付款後,在您下單時選擇的取貨日期和取貨時間時內,即
            可以前往指定分店領取;取件時請攜帶身份證明文件與服務人員核對取件人姓名,與核
            對訂單編號(訂單確認通知信函中有標示訂單編號)。若您未能於時間內領取,您可以撥
            打到店取貨訂單通知信件內的各店客服專線,由客服專員協助處理後續事宜。提醒您,
            未取件記錄達3次以上(含3次),未來將無法再使用到店取貨方式訂購。
            <br />
            依據訂單狀態,家樂福線上購物網提供取消訂單及退貨方式:
            <br />
            ．訂單支付方式是信用卡一次付清或電子支付,且是未付款或者付款失敗的狀態時,您可以取消訂單或者重新支付。
            <br />
            ．訂單支付方式是信用卡一次付清或電子支付,且是付款完成的狀態,訂單還未出貨
            前,您可以申請退訂/退款,管理人員受理您的退訂申請後,會進行退款,且款項直
            接會退回至您支付用的信用卡中。
            <br />
            訂單支付方式是信用卡一次付清或電子支付,且是付款完成的狀態,訂單已出貨,
            您不可取消訂單,也不可申請退訂,如果您還想退訂,您可以致電客服專線0809-001-365由客服專員協助處理後續事宜或者貨送達後您選擇拒收商品,後續退款事宜由平台處理。
            <br />
            若網購商品有任何瑕疵或問題,您需要進行「申請退貨」手續時,請務必攜帶發票
            及全數商品至原取貨店別(若您是宅配到貨者,請就近至任一店別)進行「申請退貨」
            手續;若僅有一項商品需要退貨,為加速您退貨的速度,請依據退貨辦法先進行全數商品退貨,再重新購買商品。
          </div>
        </Card>
      </Container>
    </Layout>
  );
};

export default ShoppingInformation;
